<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <template>
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar dense flat>
              <v-toolbar-title>Power BI</v-toolbar-title>
            </v-toolbar>
            <v-divider/>
            <div id="embedContainer" style="height: 76vh"/>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../components/commonComponents/BaseBreadcrumb";
import moment from "moment";
import * as pbi from "powerbi-client";

export default {
  components: {
    BaseBreadcrumb,
  },
  name: "Dashboard",
  data: () => ({
    page: {
      title: "Dashboard",
      name: "dashboard",
    },
    loading: false,
    report_id: null,
  }),
  created() {
    if(!this.$store.getters.getPowerBiUrl || !this.$store.getters.getPowerBiToken || !this.$store.getters.getPowerBiSecondToken ||
        this.$store.getters.getPowerBiExpDate < moment().format("X") || this.$store.getters.getPowerBiSecondExpDate < moment().format("X")) {
      this.getToken();
    }
    else {
      this.getReportID();
    }
  },
  methods: {
    getToken() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/getpowerbitoken")
          .then((response) => {
            this.$store.dispatch("setPowerBiToken", response.data.access_token);
            this.$store.dispatch("setPowerBiExpDate", moment().add(1, "hours").format("X"));
            this.loadDashboard();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    loadDashboard() {
      this.$http
          .post(this.$store.getters.appUrl + "v2/getpowerbiurl", { token: this.$store.getters.getPowerBiToken })
          .then((response) => {
            this.$store.dispatch("setPowerBiUrl", response.data.embedUrl);
            this.getSecondToken();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    getSecondToken() {
      this.$http
          .post(this.$store.getters.appUrl + "v2/getpowerbisecondtoken", { token: this.$store.getters.getPowerBiToken })
          .then((response) => {
            this.$store.dispatch("setPowerBiSecondToken", response.data.token);
            this.$store.dispatch("setPowerBiSecondExpDate", moment(response.data.expiration).format("X"));
            this.loading = false;
            this.getReportID();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    getReportID() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/getpowerbireport")
          .then((response) => {
            this.report_id = response.data;
            this.showDashboard();
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
          });
    },
    showDashboard() {
      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: this.$store.getters.getPowerBiSecondToken,
        embedUrl: this.$store.getters.getPowerBiUrl,
        id: this.report_id,
        permissions: pbi.models.Permissions.All,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true
        }
      };

      let powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
      );

      const dashboardContainer = document.getElementById('embedContainer');
      const dashboard = powerbi.embed(dashboardContainer, config);

      dashboard.off("loaded");
      dashboard.off("rendered");
      dashboard.on("error", function () {
        this.dashboard.off("error");
      });
    }
  },
};
</script>